<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Stock Opname'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/images/undraw_medical_care_movn.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col-md" v-if="imgLoaded != false">
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Tanggal</strong></td>
                    <td>{{ data.checking_date }}</td>
                  </tr>
                  <tr>
                    <td><strong>Petugas</strong></td>
                    <td>{{ data.employee_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>
                      <span v-if="data.status_id == 0"
                        ><i class="fas fa-circle text-danger"></i>
                        {{ data.status }}</span
                      >
                      <span v-if="data.status_id == 1"
                        ><i class="fas fa-circle text-success"></i>
                        {{ data.status }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Keterangan</strong></td>
                    <td>{{ data.notes }}</td>
                  </tr>
                </table>
                <button
                  @click="
                    $router.push({
                      name: 'stock-opname-edit',
                      params: { id: data.id },
                    })
                  "
                  class="btn mx-1 btn-success"
                  v-if="data.status_id == 0 && btn == true"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button>
                <button
                  class="btn mx-1 btn-danger"
                  v-if="data.status_id == 0 && btn == true"
                  @click="btnDeleteOnClick(data.id)"
                >
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Daftar Item'">
          <template v-slot:body>
            <div class="row align-items-center">
              <b-table striped hover :items="items" :fields="fields">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '20%' : '' }"
                  />
                </template>
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Data
      data: [],
      // Other
      imgLoaded: false,
      // User Access
      btn: false,

      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "inventory_name",
          label: "Inventori",
          sortable: true,
        },
        {
          key: "current_stock",
          label: "Stok Di Sistem",
          sortable: true,
        },
        {
          key: "checking_stock",
          label: "Stok Fisik",
          sortable: true,
        },
        {
          key: "difference_stock",
          label: "Selisih Stok",
          sortable: true,
        },
      ],
      items: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete(
        "inventory-stock-opname/" + this.$route.params.id
      );
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/inventory/stock-opnames");
      }
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("inventory-stock-opname/" + id);
      // If Deleted
      if (result) {
        this.pagination();
        this.$router.push({ name: "stock-opname-list" });
      }
    },

    async get() {
      this.data = await module.get(
        "inventory-stock-opname/" + this.$route.params.id
      );
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/inventory/stock-opname");
      }
      this.pagination();
    },

    async pagination() {
      let response = await module.paginate(
        `inventory-detail-stock-opname/by-stock/${this.$route.params.id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9010") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock Opname", route: "/inventory/stock-opname" },
      { title: "Detail" },
    ]);

    this.get();
    this.setActiveButton();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>